var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.readOnly)?_c('div',{staticClass:"flex items-center gap-0.5 py-1.5 px-3",class:{ 'text-gray-500': !_vm.number }},[(_vm.emoji == 'love')?_c('fw-icon-heart',{staticClass:"h-4 w-4"}):(_vm.emoji == 'thumbs_up')?_c('fw-icon-thumbs-up',{staticClass:"h-4 w-4"}):(_vm.emoji == 'dislike')?_c('fw-icon-thumbs-down',{staticClass:"h-4 w-4"}):(_vm.emoji == 'clap')?_c('fw-icon-clap',{staticClass:"h-4 w-4"}):_vm._e(),(_vm.number != null)?_c('div',{staticClass:"text-center font-semibold",class:{
      'opacity-70': !_vm.clicked,
      'w-4': _vm.tmpNumber < 10,
      'w-5': _vm.tmpNumber >= 10 && _vm.tmpNumber < 100,
      'w-7': _vm.tmpNumber >= 100 && _vm.tmpNumber < 1000,
      'w-10': _vm.tmpNumber >= 1000
    }},[_vm._v(" "+_vm._s(_vm.formatedNumber)+" ")]):_vm._e()],1):_c('button',{staticClass:"transition-all rounded-full group",class:{
    'bg-gray-50 hover:bg-gray-200 text-gray-800': !_vm.clicked,
    'bg-gray-700 hover:bg-gray-600 text-white': _vm.clicked,
    'py-1.5 px-3': _vm.size == 'md',
    'py-1 px-2': _vm.size == 'sm' || _vm.size == 'xs',
    'text-sm': _vm.size == 'md',
    'text-xs': _vm.size == 'sm' || _vm.size == 'xs'
  },on:{"click":function($event){return _vm.buttonClick()}}},[_c('div',{staticClass:"flex items-center gap-0.5"},[(_vm.emoji == 'love')?_c('fw-icon-heart',{class:{
        'h-4 w-4': _vm.size == 'md',
        'h-3 w-3': _vm.size == 'sm',
        'h-[0.8rem] w-[0.8rem]': _vm.size == 'xs'
      }}):(_vm.emoji == 'thumbs_up')?_c('fw-icon-thumbs-up',{class:{
        'h-4 w-4': _vm.size == 'md',
        'h-3 w-3': _vm.size == 'sm',
        'h-[0.8rem] w-[0.8rem]': _vm.size == 'xs'
      }}):(_vm.emoji == 'dislike')?_c('fw-icon-thumbs-down',{class:{
        'h-4 w-4': _vm.size == 'md',
        'h-3 w-3': _vm.size == 'sm',
        'h-[0.8rem] w-[0.8rem]': _vm.size == 'xs'
      }}):(_vm.emoji == 'clap')?_c('fw-icon-clap',{class:{
        'h-4 w-4': _vm.size == 'md',
        'h-3 w-3': _vm.size == 'sm',
        'h-[0.8rem] w-[0.8rem]': _vm.size == 'xs'
      }}):_vm._e(),(_vm.number != null)?_c('div',{staticClass:"text-center font-semibold",class:{
        'opacity-70': !_vm.clicked,
        'w-4': _vm.tmpNumber < 10,
        'w-5': _vm.tmpNumber >= 10 && _vm.tmpNumber < 100,
        'w-7': _vm.tmpNumber >= 100 && _vm.tmpNumber < 1000,
        'w-10': _vm.tmpNumber >= 1000
      }},[_vm._v(" "+_vm._s(_vm.formatedNumber)+" ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }